import { datadogRum } from "@datadog/browser-rum";

if (process.env.NEXT_PUBLIC_DD_ENABLED === "true") {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DD_APP_ID ?? "",
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN ?? "",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.eu",
    service: process.env.NEXT_PUBLIC_DD_SERVICE ?? "",
    env: process.env.NEXT_PUBLIC_DD_ENV ?? "",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
  });
}

export default function DatadogInit() {
  return null;
}
