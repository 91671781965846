"use client";

import dynamic from "next/dynamic";
import { redirect, useRouter, usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";

import LoadingOverlay from "@/components/molecules/loading-overlay/loading-overlay";
import { NotFound } from "@/config/app-constant";
import { RootState } from "@/store/root-reducer";
import { clearApiError } from "@/store/slices/errors/api-error.slice";
import { clearQuestion } from "@/store/slices/question/question.slice";
import { getCookieValue } from "@/utils/common.utils";
import { hideMessenger, showMessenger } from "@/utils/zendesk.utils";

import DatadogInit from "./datadog-init";

const APIErrorModal = dynamic(
  () => import("@/components/molecules/api-error-modal/api-error-modal")
);

export default function Template({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const dispatch = useDispatch();
  const router = useRouter();
  const pathname = usePathname();
  const {
    errorStatus,
    modalTitle,
    modalDescription,
    ctaPrimaryBtnLabel,
    ctaSecondaryBtnLabel,
    multiButton,
    httpCode,
    errorCode,
    errors,
    validationEnable,
    onPrimaryCtaClick,
    contactNumber,
  } = useSelector((state: RootState) => state.apiError);

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (errorStatus && httpCode === 401) {
      dispatch(clearApiError());
      dispatch(clearQuestion());
      router.replace("/");
    } else if (errorStatus) {
      setIsOpenModal(true);
    }
  }, [dispatch, isOpenModal, errorStatus, httpCode, router]);

  useEffect(() => {
    setTimeout(() => {
      if (document.cookie) {
        const OptanonAlertBoxClosed = getCookieValue(
          "OptanonAlertBoxClosed",
          document.cookie
        );
        if (OptanonAlertBoxClosed) {
          if (
            typeof window !== "undefined" &&
            window.OneTrust &&
            typeof window.OneTrust.Close === "function"
          ) {
            window.OneTrust.Close();
          }
        }
      }
    }, 2000);
    const handleConsentOneTrust = () => {
      // e contains information about the event, you can use it if needed
      const element = document.getElementById("ot-sdk-btn-floating");
      if (element) {
        element.style.display = "none";
      }
    };

    // Add event listener for "consent.onetrust"
    window.addEventListener("consent.onetrust", handleConsentOneTrust);

    // Clean up function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("consent.onetrust", handleConsentOneTrust);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      hideMessenger();
    }, 1500);

    if (process.env.NEXT_PUBLIC_ENV === "production") {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? "" });
    }

    if (window) {
      if (window.location.pathname === "/404/") {
        redirect(NotFound);
      }
    }

    return () => clearTimeout(timeoutId); // Clear timeout if the component unmounts
  }, []);

  useEffect(() => {
    if (pathname) {
      hideMessenger();
    }
  }, [pathname]);

  const handleModalClose = (value: boolean) => {
    dispatch(clearApiError());
    setIsOpenModal(value);
  };

  const handlePrimaryButtonClick = (value: boolean) => {
    if (onPrimaryCtaClick) {
      onPrimaryCtaClick({
        errorCode,
        httpCode,
        errors,
        handleModalClose,
      });
      setIsOpenModal(false);
      return;
    }
    if (errorCode && errorCode === "456") {
      window.location.href = "/";
    }
    dispatch(clearApiError());
    setIsOpenModal(value);
  };

  const handleContactUs = () => {
    if (multiButton) {
      dispatch(clearApiError());
      setIsOpenModal(false);
      showMessenger();
    }
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_DD_ENABLED === "true" && <DatadogInit />}
      <LoadingOverlay />
      <div>
        {isOpenModal && (
          <APIErrorModal
            title={modalTitle}
            description={modalDescription}
            ctaPrimaryBtnLabel={ctaPrimaryBtnLabel}
            ctaSecondaryBtnLabel={ctaSecondaryBtnLabel}
            isSecondaryCtaEnabled={multiButton}
            errors={errors}
            isOpenModal={isOpenModal}
            validationEnable={validationEnable}
            onOpenChange={(value) => handleModalClose(value)}
            onPrimaryCtaClick={(value) =>
              onPrimaryCtaClick
                ? onPrimaryCtaClick({
                    errorCode,
                    httpCode,
                    errors,
                    handleModalClose,
                  })
                : handlePrimaryButtonClick(value)
            }
            onSecondaryCtaClick={() => handleContactUs()}
            contactNumber={contactNumber}
          />
        )}
        {children}
      </div>
    </>
  );
}
